import React from "react"
import styled from "styled-components"
import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Hero = styled.section`
  background: #eae56a;
  color: #000;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
  padding: 2rem 20px;
  h1 {
    font-family: "Bebas Neue", sans-serif;
    margin: 3rem auto 0 auto;
    font-size: 3.5rem;
    text-align: center;
    letter-spacing: 0.15rem;
    flex: 0 0 100%;
  }
  a {
    color: #000000;
    text-decoration: none;
    font-size: 1.5rem;
  }
  div {
    margin: 0 auto;
  }
`

const SuccessPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: 'successpage.title'})} />
    <Hero>
      <h1><FormattedMessage id="successpage.hed" /></h1>
      <h2><FormattedMessage id="successpage.copy" /></h2>
      <p>
        <Link to="/">
          <FormattedMessage id="successpage.back" />
        </Link>
      </p>
    </Hero>
  </Layout>
)

export default injectIntl(SuccessPage)
